import { action, observable, runInAction } from "mobx";

import ClientFactory from "../../app/WebAPIClientFactory";
import { ISystemInfoDTO } from "../../app/WebAPIClients";
import Base from "../../store/base";

class Store extends Base {
    @observable public backendSysInfo: ISystemInfoDTO = null;

    @action
    public getSystemInfo = async () => {
        const sysClient = ClientFactory.getSystemClient();
        const callAPI = async () => {
            const r = await sysClient.getSystemInfo();
            runInAction(() => {
                this.backendSysInfo = r;
            });
        };

        await this.callAPIFunForLoadData(callAPI);
    };
}

export default new Store();
