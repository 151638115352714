import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import { DatetimeField, Field, Group, SwitchField } from "saltui";
import Perm from "../../../app/Perm";
import {
    IBlockPlanningRecordUpdateDTO,
    IBlockSlabPlanningItemDTO,
    IBlockTilePlanningOutputRates,
    IStoneCategoryDTO,
    IStoneGradeDTO
} from "../../../app/WebAPIClients";
import BlockDimensionField from "../../../components/BlockDimensionField";
import Consts from "../../../components/Consts";
import ESHeader from "../../../components/ESHeader";
import PermCtl from "../../../components/PermCtrl";
import Settings from "../../../components/SettingInfo";
import SettingsDefinition from "../../../components/SettingsDefinition";
import { BlockDimensionType } from "../../../components/type";
import Util from "../../../components/Util";
import Store from "./store";
import SlabPlanningItemTable from "../../../components/BlockPlanningRecord/SlabPlanningItemTable";
import TilePlanningItemsEditor from "../../../components/BlockPlanningRecord/TilePlanningItemsEditor";
import ButtonGroup from "../../../components/ButtonGroup";
import validator from "../../../components/Validator";
import ESPhotoField from "../../../components/ESPhotoField";
import { IPlanningOutputRatesForBackend } from "../../../components/BlockPlanningRecord/interfaces";
import PlanningRecordUtil from "../../../components/BlockPlanningRecord/PlanningRecordUtil";

const { BlockStatus, ImageTypes, StoneCategories, StoneGrades } = Consts;

declare interface IPageEditPlanningRecordProps
    extends RouteComponentProps<{
            id: string;
        }> {}

declare interface IPagePlanningRecordState {
    categoryList?: IStoneCategoryDTO[];
    gradeList?: IStoneGradeDTO[];
    blockId?: number;
    enableBlockNumberFromQuarry: boolean;
    cracksExist: boolean;
    assessmentDateValue: number;
    slabPlanningItems: IBlockSlabPlanningItemDTO[];
    slabOutputRateByGrade: IPlanningOutputRatesForBackend;
    noTileOutputRates: boolean;
    tilePlanningOutputRates: IBlockTilePlanningOutputRates;
    imageNames?: string;
}
@observer
class PageEditPlanningRecord extends React.Component<
    IPageEditPlanningRecordProps,
    IPagePlanningRecordState
> {
    constructor(props) {
        super(props);
        this.state = {
            blockId: parseInt(this.props.match.params.id),
            enableBlockNumberFromQuarry: Settings.getBoolValue(
                SettingsDefinition.EnableBlockNumberFromQuarryKey
            ),
            cracksExist: false,
            assessmentDateValue: new Date().getTime(),
            slabPlanningItems: [],
            slabOutputRateByGrade: null,
            noTileOutputRates: false,
            tilePlanningOutputRates: null
        };
    }

    public async componentDidMount() {
        const categoryList = await StoneCategories.getCategories();
        const gradeList = await StoneGrades.getGrades();

        this.setState({
            categoryList,
            gradeList
        });
        await this.loadData();
    }

    public componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            this.setState({ blockId: nextProps.match.params.id }, () =>
                this.loadData()
            );
        }
    }

    public render(): JSX.Element {
        const t = this;
        const s = t.state;
        const { block, blockLoaded, tileSizes } = Store;
        if (!block) {
            return <span />;
        }
        const canViewPlanningRecord = PermCtl.isAuthorized(Perm.B_PR_R);

        if (!canViewPlanningRecord) {
            return (
                <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
                    您无权访问这个页面
                </div>
            );
        }

        const {
            cracksExist,
            assessmentDateValue,
            slabPlanningItems,
            noTileOutputRates,
            tilePlanningOutputRates,
            imageNames
        } = s;

        const showStockInInfo =
            block.validatedHeight !== null &&
            block.validatedLength !== null &&
            block.validatedWidth !== null;
        return (
            <div>
                <Group>
                    {blockLoaded ? (
                        <div>
                            <ESHeader label={"荒料信息"} />
                            <Group.List lineIndent={15}>
                                <Field label="荒料编号">
                                    {block.blockNumber}{" "}
                                </Field>
                                {s.enableBlockNumberFromQuarry ? (
                                    <Field label="矿山荒料号">
                                        {block.blockNumberFromQuarry}
                                    </Field>
                                ) : null}
                                <BlockDimensionField
                                    block={block}
                                    dimensionType={
                                        BlockDimensionType.QuarryReported
                                    }
                                />
                                <Field label="收方体积">
                                    {Util.calculateVolume(
                                        block.quarryReportedLength,
                                        block.quarryReportedWidth,
                                        block.quarryReportedHeight
                                    )}立方
                                </Field>
                                {showStockInInfo ? (
                                    <BlockDimensionField
                                        block={block}
                                        dimensionType={
                                            BlockDimensionType.Validated
                                        }
                                    />
                                ) : null}
                                {showStockInInfo ? (
                                    <Field label="入库体积">
                                        {Util.calculateVolume(
                                            block.validatedLength,
                                            block.validatedWidth,
                                            block.validatedHeight
                                        )}立方
                                    </Field>
                                ) : null}
                                <Field label="荒料状态">
                                    {BlockStatus.getStatus(block.status).text}
                                </Field>
                                <ESHeader label={"规划方案"} />
                                <SwitchField
                                    label="荒料有裂"
                                    on={cracksExist}
                                    onChange={t.handleCrackExistsChange}
                                />
                                <DatetimeField
                                    label="评估日期"
                                    placeholder="请选择评估日期"
                                    onSelect={t.handleAssessedDateChange}
                                    value={assessmentDateValue}
                                    columns={DatetimeField.YMD}
                                    required={true}
                                />
                                <ESHeader label={"大板规划方案"} />
                                <SlabPlanningItemTable
                                    allowEdit={true}
                                    items={slabPlanningItems}
                                    onChange={
                                        t.handleSlabPlanningOutputRatesChange
                                    }
                                />
                                <ESHeader label="成品板规划方案" />
                                <SwitchField
                                    label="无出材率"
                                    on={noTileOutputRates}
                                    onChange={t.handleNoTileOutputRatesChange}
                                    tip="如果荒料无成品板出材率，请打开此开关"
                                />
                                {noTileOutputRates ? null : (
                                    <TilePlanningItemsEditor
                                        outputRates={tilePlanningOutputRates}
                                        tileSizes={tileSizes}
                                        onChange={
                                            t.handleTilePlanningOutputRatesChange
                                        }
                                    />
                                )}
                                <ESPhotoField
                                    label="附件"
                                    placeholder="请选择图片"
                                    maxUpload={9}
                                    onDelete={t.handleImageNamesChange}
                                    onImageUpload={t.handleImageNamesChange}
                                    imageNames={imageNames}
                                />
                            </Group.List>
                        </div>
                    ) : (
                        <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
                            {Store.hasError ? "加载数据错误" : "没有数据"}
                        </div>
                    )}
                    <ButtonGroup onGoBack={t.goBack} onSubmit={t.submitData} />
                </Group>
            </div>
        );
    }

    private handleCrackExistsChange = (status: boolean): void => {
        this.setState({ cracksExist: status });
    };

    private handleNoTileOutputRatesChange = (status: boolean): void => {
        this.setState({ noTileOutputRates: status });
    };

    private handleAssessedDateChange = (value: {
        value: number;
        timeType: string;
    }): void => {
        this.setState({ assessmentDateValue: value.value });
    };

    private handleSlabPlanningOutputRatesChange = (
        outputRates: IPlanningOutputRatesForBackend
    ): void => {
        this.setState({ slabOutputRateByGrade: outputRates });
    };

    private handleTilePlanningOutputRatesChange = (
        outputRates: IBlockTilePlanningOutputRates
    ): void => {
        this.setState({ tilePlanningOutputRates: outputRates });
    };

    private handleImageNamesChange = (imageNames: string): void => {
        this.setState({ imageNames });
    };

    private async loadData(): Promise<void> {
        const t = this;
        const s = t.state;

        if (s.blockId) {
            await Store.loadData(s.blockId);
            if (!Store.hasError) {
                const record = Store.planningRecord;
                if (record) {
                    const cracksExist = record.cracksExist;
                    const assessmentDateValue = new Date(
                        record.assessmentDate
                    ).getTime();
                    const slabPlanningItems = record.slabPlanningItems;
                    const slabOutputRateByGrade = PlanningRecordUtil.convertSlabPlanningItemsToOutputRates(
                        slabPlanningItems
                    );
                    const noTileOutputRates = record.noTileOutputRates;
                    const tilePlanningOutputRates =
                        record.tilePlanningOutputRates;
                    const imageNames = Util.getImageFileNamesByType(
                        record.imageFiles,
                        ImageTypes.attachment
                    );
                    t.setState({
                        cracksExist,
                        assessmentDateValue,
                        slabPlanningItems,
                        slabOutputRateByGrade,
                        noTileOutputRates,
                        tilePlanningOutputRates,
                        imageNames
                    });
                }
            }
        }
    }

    private goBack = (): void => {
        this.props.history.goBack();
    };

    private validate(): boolean {
        const t = this;
        const s = t.state;

        const {
            slabOutputRateByGrade,
            noTileOutputRates,
            tilePlanningOutputRates
        } = s;

        let valid: boolean = t.validateOutputRate(
            slabOutputRateByGrade,
            "石材等级"
        );

        if (!noTileOutputRates) {
            valid =
                valid &&
                validator.require(
                    tilePlanningOutputRates,
                    "成品板长度和宽度的出材率",
                    true,
                    "输入",
                    true
                );
            valid =
                valid &&
                t.validateOutputRate(
                    tilePlanningOutputRates.blockLengthAsTileLengthOutputRate,
                    "荒料长度适配成品板长度"
                );
            valid =
                valid &&
                t.validateOutputRate(
                    tilePlanningOutputRates.blockWidthAsTileLengthOutputRate,
                    "荒料宽度适配成品板长度"
                );
            valid =
                valid &&
                t.validateOutputRate(
                    tilePlanningOutputRates.blockHeightAsTileWidthOutputRate,
                    "荒料高度适配成品板宽度"
                );
        }

        return valid;
    }

    private validateOutputRate(
        outputRate: IPlanningOutputRatesForBackend,
        type:
            | "石材等级"
            | "荒料长度适配成品板长度"
            | "荒料宽度适配成品板长度"
            | "荒料高度适配成品板宽度"
    ): boolean {
        if (outputRate === null) {
            validator.showAlert(`请输入至少一个${type}的出材率`, true);
            return false;
        }

        const entries = Object.entries(outputRate);
        let valid = true;
        for (let [key, value] of entries) {
            let fieldName = type === "石材等级" ? `${type}Id` : type;
            let minValue = type === "石材等级" ? 1 : 400;
            let maxValue = type === "石材等级" ? 2147483647 : 3000;
            valid = valid && validator.numeric(key, fieldName, true, true);
            valid =
                valid &&
                validator.range(key, minValue, maxValue, fieldName, true, true);
            fieldName = `${type}出材率`;
            minValue = type === "石材等级" ? 1 : 10;
            maxValue = 100;
            valid = valid && validator.numeric(value, fieldName, true, true);
            valid =
                valid &&
                validator.range(
                    value * 100,
                    minValue,
                    maxValue,
                    fieldName,
                    true,
                    true
                );
        }
        return valid;
    }

    private submitData = async (): Promise<void> => {
        const t = this;
        const { blockId } = t.state;
        const valid = this.validate();
        if (!valid) {
            return;
        }

        const {
            cracksExist,
            assessmentDateValue,
            slabOutputRateByGrade,
            noTileOutputRates,
            tilePlanningOutputRates,
            imageNames
        } = t.state;
        const assessmentDate = new Date(assessmentDateValue);
        const record: IBlockPlanningRecordUpdateDTO = {
            cracksExist,
            assessmentDate,
            slabOutputRateByGrade,
            noTileOutputRates,
            blockLengthAsTileLengthOutputRate: noTileOutputRates
                ? null
                : tilePlanningOutputRates.blockLengthAsTileLengthOutputRate,
            blockWidthAsTileLengthOutputRate: noTileOutputRates
                ? null
                : tilePlanningOutputRates.blockWidthAsTileLengthOutputRate,
            blockHeightAsTileWidthOutputRate: noTileOutputRates
                ? null
                : tilePlanningOutputRates.blockHeightAsTileWidthOutputRate,
            imageNames
        };
        await Store.submitData(blockId, record);
        if (!Store.hasError) {
            t.goBack();
        }
    };
}

export default PageEditPlanningRecord;
