import { action, observable, runInAction } from "mobx";
import Perm from "../../../app/Perm";
import ClientFactory from "../../../app/WebAPIClientFactory";
import { ISlabGradeChangeRequestForListDTO } from "../../../app/WebAPIClients";
import Consts from "../../../components/Consts";
import PermCtl from "../../../components/PermCtrl";
import Util from "../../../components/Util";
import Base from "../../../store/base";
import { ISGCRListFilterData } from "../../../components/SlabGradeChangeRequest/SGCRListFilter";
const { ApprovalStatus: ApprovalStatusOptions } = Consts;

class Store extends Base {
  @observable public requestList: ISlabGradeChangeRequestForListDTO[] = [];
  @observable public id: number = null;
  @observable
  public filterData: ISGCRListFilterData = {
    requestNumber: undefined,
    requestStatus: [],
  };
  @observable
  public newSGCRFilterData: ISGCRListFilterData = {
    requestNumber: null,
  };
  @observable
  public activeTabIndex: string = PermCtl.isAnyAuthorized([
    Perm.SGCR_CU,
    Perm.SGCR_CL,
  ])
    ? "0"
    : "1";

  @action
  public updataActiveTabIndex = (activeTabIndex: string) => {
    this.activeTabIndex = activeTabIndex;
  };
  @action
  public updataAllSGCRFilterData = (filterData: ISGCRListFilterData) => {
    this.filterData = filterData;
  };

  @action
  public updataNewSGCRFilterData = (filterData: ISGCRListFilterData) => {
    this.newSGCRFilterData = filterData;
  };

  @action
  public loadMoreData = async (): Promise<void> => {
    const t = this;
    const newPageNo: number = t.pageNo + 1;
    const callAPI = async () => {
      const content = await t.getServerData(newPageNo);
      runInAction(
        () => (this.requestList = [...t.requestList, ...content.items]),
      );
      this.setPagingInfo(content.pagingInfo);
    };

    await this.callAPIFunForLoadMoreData(callAPI, "等级变更单");
  };

  @action
  public reloadData = async (): Promise<void> => {
    const t = this;
    const callAPI = async () => {
      const content = await t.getServerData(1);
      runInAction(() => (this.requestList = content.items));
      this.setPagingInfo(content.pagingInfo);
    };

    await this.callAPIFunForLoadData(callAPI, "等级变更单");
  };

  private getServerData = (pageNo: number) => {
    const t = this;

    const filterData: ISGCRListFilterData =
      t.activeTabIndex === "0" ? t.newSGCRFilterData : t.filterData;
    const { requestStatus, requestNumber, bundlePrefix, slabSequenceNumber } =
      filterData;
    const statusOptions =
      t.activeTabIndex === "0"
        ? ApprovalStatusOptions.pendingApprovalData
        : requestStatus;

    const client = ClientFactory.getSlabGradeChangeRequestClient();
    return client.search(
      requestNumber,
      Util.getValuesByOptions(statusOptions),
      bundlePrefix,
      slabSequenceNumber,
      undefined,
      t.pageSize,
      pageNo,
    );
  };
}

export default new Store();
