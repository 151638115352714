import { action, observable, runInAction } from "mobx";

import ClientFactory from "../../app/WebAPIClientFactory";
import { LoginResultDTO } from "../../app/WebAPIClients";
import Base from "../../store/base";

class Store extends Base {
    @observable public loggedIn: boolean = false;
    @observable public loginResult: LoginResultDTO = null;
    @observable
    public fullTenantInfo: Array<{
        value: string;
        text: string;
    }> = [];

    @action
    public devLogin = async ({
        userName,
        tenantAbbrCode
    }: {
        userName: string;
        tenantAbbrCode: any;
    }): Promise<void> => {
        const t = this;
        const client = ClientFactory.getSecurityClient();
        const callAPI = async () => {
            const result = await client.loginByUserName(
                userName,
                tenantAbbrCode
            );

            runInAction(() => {
                this.loggedIn = true;
                this.loginResult = result;
            });
        };

        await t.callAPIFunForSubmit(callAPI, "正在登录", "登录失败");
    };

    @action
    public getAllTenant = async (): Promise<void> => {
        const t = this;
        const client = ClientFactory.getSecurityClient();
        const callAPI = async () => {
            const result = await client.getAllTenants();
            const fullTenantInfo = result.map(r => {
                return { value: r.abbreviationCode, text: r.name };
            });
            runInAction(() => {
                this.fullTenantInfo = fullTenantInfo;
            });
        };

        await this.callAPIFunForLoadData(callAPI);
    };
}

export default new Store();
