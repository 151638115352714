import { action, observable, runInAction } from "mobx";

import Base from "../store/base";
import ClientFactory from "./WebAPIClientFactory";
import { IDingtalkConfigDTO, ILoginResultDTO } from "./WebAPIClients";

class Store extends Base {
    @observable public ddConfig: IDingtalkConfigDTO = null;
    @observable public authCode: string = "";
    @observable public loggedIn: boolean = false;
    @observable public loginResult: ILoginResultDTO = null;

    @action
    public getConfig = async ({
        clientUrl,
        tenantAbbrCode
    }: {
        clientUrl: string;
        tenantAbbrCode: string;
    }): Promise<void> => {
        const t = this;
        const dingtalkClient = ClientFactory.getDingtalkClient();
        const callAPI = async () => {
            const result = await dingtalkClient.getMobileConfig(
                clientUrl,
                tenantAbbrCode
            );
            runInAction(() => {
                this.ddConfig = result;
            });
        };

        await this.callAPIFunForLoadData(callAPI);
    };

    @action
    public login = async ({
        authCode,
        tenantAbbrCode
    }: {
        authCode: string;
        tenantAbbrCode: string;
    }): Promise<void> => {
        const securityClient = ClientFactory.getSecurityClient();
        const callAPI = async () => {
            const result = await securityClient.login(authCode, tenantAbbrCode);
            runInAction(() => {
                this.loginResult = result;
                this.loggedIn = true;
            });
        };
        await this.callAPIFunForSubmit(callAPI);
    };
}

export default new Store();
