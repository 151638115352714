import { action, observable, runInAction } from "mobx";

import ClientFactory from "../../app/WebAPIClientFactory";
import Base from "../../store/base";

class Store extends Base {
    @observable public id: number = null;

    @action
    public getIdByUniqueId = async ({
        uniqueId,
        type
    }: {
        uniqueId: string;
        type: string;
    }): Promise<void> => {
        const callAPI = async () => {
            const result = await ClientFactory.getGuidClient().get(
                uniqueId,
                type
            );
            runInAction(() => {
                this.id = result;
            });
        };
        await this.callAPIFunForLoadData(callAPI);
    };
}

export default new Store();
