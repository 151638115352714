import { action, autorun, observable } from "mobx";
import { Toast } from "saltui";
import { ApiException, PagingInfo } from "../app/WebAPIClients";
import ErrorHandler from "../components/ErrorHandler";
import ESButtonStore from "../components/ESButton/store";
import ESDialog from "../components/ESDialog";
import ScrollerStore from "../components/ESScroller/store";

export default class Base {
    @observable public hasError: boolean = false;
    @observable
    public errorObj: { message: string; code: number } | null = null;
    @observable public errorTitle: string | null = null;
    @observable public loading: boolean = false;
    @observable public loadingActionMessage: string | null = null;
    @observable public pagingInfo?: PagingInfo | null = null;
    @observable public pageSize?: number = 10;
    @observable public pageNo?: number = 1;

    protected listenable = autorun(() => {
        if (this.loading) {
            Toast.hide();
            Toast.show({ content: this.loadingActionMessage });
        } else {
            Toast.hide();
            if (this.hasError) {
                ErrorHandler.handleErr(this.errorTitle!, this.errorObj!);
            }
        }
    });

    @action
    public apiCallBegin = (msg: string) => {
        this.hasError = false;
        this.errorObj = null;
        this.errorTitle = null;
        this.loading = true;
        this.loadingActionMessage = msg;
    };

    @action
    public apiCallReset = () => {
        this.hasError = false;
        this.errorObj = null;
        this.errorTitle = null;
        this.loading = false;
        this.loadingActionMessage = null;
    };

    @action
    public apiCallFailure = (errorTitle: string, errorObj: any) => {
        this.hasError = true;
        this.errorObj = errorObj;
        this.errorTitle = errorTitle;
        this.loading = false;
        this.loadingActionMessage = null;
    };

    @action
    public setPagingInfo = (pagingInfo: PagingInfo | undefined | null) => {
        this.pagingInfo = pagingInfo;
        if (pagingInfo && pagingInfo.currentPage) {
            this.pageNo = pagingInfo.currentPage;
        }
    };

    @action
    public setPageSize = (pageSize: number) => {
        this.pageSize = pageSize;
    };

    @action
    public setPageNo = (pageNo: number) => {
        this.pageNo = pageNo;
    };

    public callAPIFun = async (
        callAPI: () => Promise<void>,
        msg?: string,
        errMsg?: string
    ) => {
        this.apiCallBegin(msg);
        try {
            await callAPI();
            this.apiCallReset();
        } catch (error) {
            this.apiCallFailure(errMsg, this.getErrorState(error));
        }
    };

    public callAPIFunForSubmit = async (
        callAPI: () => Promise<void>,
        msg: string = "正在提交数据...",
        errMsg: string = "提交数据失败"
    ) => {
        ESButtonStore.updateState();
        await this.callAPIFun(callAPI, msg, errMsg);
        ESButtonStore.clearData();
        ESDialog.Reset(); // 将部分界面会用到ESDialog，这里默认调用reset
    };

    public callAPIFunForLoadData = async (
        callAPI: () => Promise<void>,
        dataType: string = "数据"
    ) => {
        ScrollerStore.updateState();
        await this.callAPIFun(
            callAPI,
            "正在加载" + dataType + "...",
            "载入" + dataType + "失败"
        );
        ScrollerStore.clearData();
    };

    public callAPIFunForLoadMoreData = async (
        callAPI: () => Promise<void>,
        dataType: string = "数据"
    ) => {
        ScrollerStore.updateState();
        await this.callAPIFun(
            callAPI,
            "加载" + dataType + "列表...",
            "获取" + dataType + "列表失败"
        );
        ScrollerStore.clearData();
    };

    private getErrorState(ex: ApiException) {
        return {
            message: ex.response || ex.message,
            code: ex.status || -1
        };
    }
}
