import { IWarehouseDTO } from "../app/WebAPIClients";
import ClientFactory from "../app/WebAPIClientFactory";
import CachedDataStore from "./CachedDataStore";

class WarehouseStore extends CachedDataStore<IWarehouseDTO[]> {
  private static instance: WarehouseStore;
  private constructor() {
    super("warehousesStorage", "warehousesCache");
  }

  public static getInstance(): WarehouseStore {
    if (!WarehouseStore.instance) {
      WarehouseStore.instance = new WarehouseStore();
    }
    return WarehouseStore.instance;
  }

  public async getWarehouse(id: number): Promise<IWarehouseDTO | null> {
    const warehouseList = await this.getWarehouses();
    return warehouseList.find((c) => c.id === id) ?? null;
  }

  public async getWarehouses(): Promise<IWarehouseDTO[]> {
    await this.ensureWarehouses();
    return this.getCachedData();
  }

  public async ensureWarehouses() {
    const loadDataFn = async () => {
      const client = ClientFactory.getWarehouseClient();
      return await client.getAll();
    };

    await this.loadAndCacheData(loadDataFn);
  }
}

export default WarehouseStore.getInstance();
