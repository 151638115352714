import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Tab } from "saltui";
import Perm from "../../../app/Perm";
import { ISlabGradeChangeRequestForListDTO } from "../../../app/WebAPIClients";
import Consts from "../../../components/Consts";
import PagedList from "../../../components/PagedList";
import PermCtl from "../../../components/PermCtrl";
import Util from "../../../components/Util";
import Store from "./store";
import SGCRListFilter, {
  ISGCRListFilterData,
} from "../../../components/SlabGradeChangeRequest/SGCRListFilter";
import SGCRList from "../../../components/SlabGradeChangeRequest/SGCRList";
const { ApprovalStatus: ApprovalStatusOptions } = Consts;

declare interface IPageSGCRListProps extends RouteComponentProps<{}> {}

declare interface IPageSGCRListState {
  allSGCRFilterExpanded: boolean;
  newSGCRFilterExpanded: boolean;
}
@observer
class PageSGCRList extends React.Component<
  IPageSGCRListProps,
  IPageSGCRListState
> {
  private readonly newSGCRTabKey = 0;
  private readonly allSGCRTabKey = 1;
  constructor(props) {
    super(props);
    this.state = {
      allSGCRFilterExpanded: false,
      newSGCRFilterExpanded: false,
    };
  }

  public async componentDidMount() {
    await this.reloadData();
  }

  public render(): JSX.Element {
    const t = this;
    const canViewPendingRequests = PermCtl.isAnyAuthorized([
      Perm.SGCR_CU,
      Perm.SGCR_CL,
    ]);

    let pendingRequestsTab = null;
    const tabArr = [];
    if (canViewPendingRequests) {
      pendingRequestsTab = this.getPendingRequestsTab();
      tabArr.push(pendingRequestsTab);
    }

    tabArr.push(this.getAllRequestsTab());

    return (
      <div>
        <Tab
          onChange={t.handleTabChange}
          activeKey={Store.activeTabIndex}
          animated={false}
        >
          {tabArr}
        </Tab>
      </div>
    );
  }

  private getPendingRequestsTab(): JSX.Element {
    const t = this;
    const s = t.state;
    const { requestList, pagingInfo, loadMoreData, hasError } = Store;
    const canCreateSGCR = PermCtl.isAuthorized(Perm.SGCR_CU);

    return (
      <Tab.Item title="等待审批" key={this.newSGCRTabKey.toString()}>
        <SGCRListFilter
          onSearch={t.handleSearch}
          onClear={t.handleClearData}
          onExpansionChanged={t.handleNewSGCRFilterExpansionChange}
          expanded={s.newSGCRFilterExpanded}
          filterData={Store.newSGCRFilterData}
          allowCustomerChange={true}
          showStatusSelector={false}
        />
        <PagedList
          className={
            s.newSGCRFilterExpanded
              ? "filterForThreeWithTabExpanded"
              : "filterCollapsedWithTab"
          }
          pagingInfo={pagingInfo}
          showTotalRecordCount={!s.newSGCRFilterExpanded}
          loadMoreDataActionFun={loadMoreData}
          error={hasError}
          dataType="等级变更单"
          showDataLoadingMessage={Util.isNotNullAndNotEmptyArray(requestList)}
        >
          <SGCRList
            requests={requestList}
            onCreateItemClick={t.handleNewItemClick}
            onItemClick={t.handleClick}
            showCreateItemIcon={canCreateSGCR}
          />
        </PagedList>
      </Tab.Item>
    );
  }

  private getAllRequestsTab(): JSX.Element {
    const t = this;
    const s = t.state;
    const { requestList, filterData, pagingInfo, loadMoreData, hasError } =
      Store;
    const canCreateSGCR = PermCtl.isAuthorized(Perm.SGCR_CU);

    return (
      <Tab.Item title="全部等级变更单" key={this.allSGCRTabKey.toString()}>
        <SGCRListFilter
          onSearch={t.handleSearch}
          onClear={t.handleClearData}
          onExpansionChanged={t.handleFilterExpansionChange}
          expanded={s.allSGCRFilterExpanded}
          filterData={filterData}
          showStatusSelector={true}
        />
        <PagedList
          className={
            s.allSGCRFilterExpanded
              ? "filterForFourWithTabExpanded"
              : "filterCollapsedWithTab"
          }
          pagingInfo={pagingInfo}
          showTotalRecordCount={!s.allSGCRFilterExpanded}
          loadMoreDataActionFun={loadMoreData}
          error={hasError}
          dataType="等级变更单"
          showDataLoadingMessage={Util.isNotNullAndNotEmptyArray(requestList)}
        >
          <SGCRList
            requests={requestList}
            onCreateItemClick={t.handleNewItemClick}
            onItemClick={t.handleClick}
            showCreateItemIcon={canCreateSGCR}
          />
        </PagedList>
      </Tab.Item>
    );
  }

  private handleTabChange = async (tabObj): Promise<void> => {
    const t = this;

    if (!tabObj) {
      return;
    }

    const activeTabIndex: string = tabObj.activeKey;
    if (activeTabIndex === Store.activeTabIndex) {
      return;
    }

    Store.updataActiveTabIndex(activeTabIndex);
    await this.reloadData();
  };

  private async reloadData(): Promise<void> {
    await Store.reloadData();
  }

  private handleClick = (item: ISlabGradeChangeRequestForListDTO): void => {
    if (!item) {
      return;
    }

    this.props.history.push("/slabgradechangerequests/info/" + item.id);
  };

  private handleNewItemClick = (): void => {
    this.props.history.push("/slabgradechangerequests/new");
  };

  private handleFilterExpansionChange = (filterExpanded: boolean): void => {
    const t = this;
    t.setState({ allSGCRFilterExpanded: filterExpanded });
  };

  private handleNewSGCRFilterExpansionChange = (
    filterExpanded: boolean,
  ): void => {
    const t = this;
    t.setState({ newSGCRFilterExpanded: filterExpanded });
  };

  private handleSearch = async (
    filterData: ISGCRListFilterData,
  ): Promise<void> => {
    const t = this;
    let newStateForExpanded = null;
    const activeTabIndex = Store.activeTabIndex;
    if (activeTabIndex === this.newSGCRTabKey.toString()) {
      Store.updataNewSGCRFilterData(filterData);
      newStateForExpanded = { newPOFilterExpanded: false };
    } else if (activeTabIndex === this.allSGCRTabKey.toString()) {
      Store.updataAllSGCRFilterData(filterData);
      newStateForExpanded = { filterExpanded: false };
    }

    await t.reloadData();
    this.setState(newStateForExpanded);
  };

  private handleClearData = async (
    filterData: ISGCRListFilterData,
  ): Promise<void> => {
    const t = this;
    const s = t.state;
    const activeTabIndex = Store.activeTabIndex;
    if (activeTabIndex === this.newSGCRTabKey.toString()) {
      const data = { ...filterData };
      data.requestStatus = ApprovalStatusOptions.pendingApprovalData;
      Store.updataNewSGCRFilterData(data);
    } else if (activeTabIndex === this.allSGCRTabKey.toString()) {
      Store.updataAllSGCRFilterData(filterData);
    }
    await this.reloadData();
  };
}

export default PageSGCRList;
