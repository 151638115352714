import { action, observable, runInAction } from "mobx";
import Perm from "../../../app/Perm";
import ClientFactory from "../../../app/WebAPIClientFactory";
import {
    BlockDTO,
    BlockPlanningRecordDTO,
    BlockPlanningRecordUpdateDTO,
    IBlockPlanningRecordDTO,
    IBlockPlanningRecordUpdateDTO,
    IBlockTilePlanningSizeDTO
} from "../../../app/WebAPIClients";
import PermCtl from "../../../components/PermCtrl";
import Base from "../../../store/base";

class Store extends Base {
    @observable public block: BlockDTO = null;
    @observable public blockLoaded: boolean = false;
    @observable public planningRecord: IBlockPlanningRecordDTO = null;
    @observable public tileSizes: IBlockTilePlanningSizeDTO[] = null;

    @action
    public loadData = async (blockId: number): Promise<void> => {
        const t = this;
        const blockClient = ClientFactory.getBlockClient();
        const canViewPlanningRecord = PermCtl.isAuthorized(Perm.B_PR_R);

        const p1 = blockClient.get(blockId);
        const p2 = blockClient.getTilePlanningSizes();
        const p3 = canViewPlanningRecord
            ? blockClient.getPlanningRecord(blockId)
            : new Promise<BlockPlanningRecordDTO>((r, _) => {
                  r(null);
              });

        const callAPI = async () => {
            const results = await Promise.all([p1, p2, p3]);
            const block = results[0];
            const tileSizes = results[1];
            const planningRecord = results[2];

            runInAction(() => {
                this.block = block;
                this.blockLoaded = true;
                this.tileSizes = tileSizes;
                this.planningRecord = planningRecord;
            });
        };

        await this.callAPIFunForLoadData(callAPI);
    };

    @action
    public submitData = async (
        blockId: number,
        planningRecord: IBlockPlanningRecordUpdateDTO
    ): Promise<void> => {
        const t = this;
        const client = ClientFactory.getBlockClient();

        const callAPI = async () => {
            const dto = BlockPlanningRecordUpdateDTO.fromJS(planningRecord);
            await client.updatePlanningRecord(blockId, dto);
        };

        await this.callAPIFunForSubmit(callAPI);
    };
}

export default new Store();
