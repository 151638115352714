import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import { SelectField, TextField } from "saltui";
import ESButton from "../../components/ESButton";
import Settings from "../../components/SettingInfo";
import tenantInfo from "../../components/TenantInfo";
import UserInfo from "../../components/UserInfo";
import Util from "../../components/Util";
import Store from "./store";

declare interface IDevloginProps extends RouteComponentProps<{}> {}
declare interface IDevloginState {
    userName: string;
    tenantAbbrCode: string;
    returnToPath: string;
}
@observer
class Devlogin extends React.Component<IDevloginProps, IDevloginState> {
    constructor(props: any) {
        super(props);
        this.state = {
            userName: "",
            tenantAbbrCode: null,
            returnToPath: Util.getLocState<string>(this, "nextPathname")
        };
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public render() {
        const t = this;
        const s = t.state;
        const fullTenantInfo = Store.fullTenantInfo;

        const tenantOption: IOption = fullTenantInfo.find(tenant => {
            return tenant.value === s.tenantAbbrCode;
        });
        return (
            <div>
                <SelectField
                    label="企业"
                    options={fullTenantInfo}
                    onSelect={t.handleTenantAbbrCodeChange}
                    value={tenantOption}
                    placeholder="请输入"
                />
                <TextField
                    label="用户名"
                    placeholder="请输入"
                    value={s.userName}
                    onChange={t.handleUserNameChange}
                />
                <div className="actionBtnContainer">
                    <ESButton onClick={t.login}>登录</ESButton>
                </div>
            </div>
        );
    }

    private async loadData() {
        const t = this;
        await Store.getAllTenant();
        if (!Store.hasError && Store.fullTenantInfo) {
            // 如果只有一个Tenant，默认选中
            const tenants = Store.fullTenantInfo;
            if (tenants && tenants.length === 1) {
                t.setState({ tenantAbbrCode: tenants[0].value as string });
            }
        }
    }

    private handleTenantAbbrCodeChange = (value: IOption): void => {
        this.setState({ tenantAbbrCode: value.value as string });
    };

    private handleUserNameChange = (value: string): void => {
        this.setState({ userName: value });
    };

    private login = async (): Promise<void> => {
        const t = this;
        const s = t.state;
        await Store.devLogin({
            userName: s.userName,
            tenantAbbrCode: s.tenantAbbrCode
        });
        if (!Store.hasError && Store.loginResult) {
            const result = Store.loginResult;
            const ui = result.userInfo;
            const si = result.settings;
            const ti = result.tenantInfo;
            const jwtToken = result.jwtToken;
            UserInfo.setUserInfo(ui);
            UserInfo.setAccessToken(jwtToken);
            Settings.setSettings(si);
            tenantInfo.setTenantInfo(ti);

            const returnToPath: string =
                s.returnToPath === null ? "/" : s.returnToPath;
            t.props.history.push(returnToPath);
        }
    };
}

export default Devlogin;
