import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";

import { Dialog, Field, Group } from "saltui";
import Perm from "../../../app/Perm";
import ButtonGroup from "../../../components/ButtonGroup";
import Collapsible from "../../../components/Collapsible";
import Consts from "../../../components/Consts";
import DateUtil from "../../../components/DateUtil";
import ESHeader from "../../../components/ESHeader";
import PermCtrl from "../../../components/PermCtrl";
import PermCtl from "../../../components/PermCtrl";
import Settings from "../../../components/SettingInfo";
import SettingsDefinition from "../../../components/SettingsDefinition";
import validator from "../../../components/Validator";
import Store from "./store";
import SlabGradeChangeContentListItem from "../../../components/SlabGradeChangeRequest/SlabGradeChangeContentListItem";
import { ApprovalStatus } from "../../../app/WebAPIClients";
import SGCRHelper from "../../../components/SlabGradeChangeRequest/SGCRHelper";

const { StoneGrades, ApprovalStatus: ApprovalStatusConsts } = Consts;

declare interface IPageSGCRInfo extends RouteComponentProps<{ id: string }> {}

declare interface IPageSGCRInfoState {
  id: number;
  gradeList?: IIdNameItem[];
  cancelReason?: string;
  showConfirm: boolean;
  text?: string;
  submissionApprovalInfoExpanded: boolean;
  enableReferencePrice: boolean;
  canViewSlabRP: boolean;
}

@observer
class PageSGCRInfo extends React.Component<IPageSGCRInfo, IPageSGCRInfoState> {
  constructor(props) {
    super(props);
    this.state = {
      id: parseInt(this.props.match.params.id),
      showConfirm: false,
      submissionApprovalInfoExpanded: false,
      enableReferencePrice: Settings.getBoolValue(
        SettingsDefinition.EnableReferencePriceKey,
      ),
      canViewSlabRP: PermCtrl.isAuthorized(Perm.S_RP_R),
    };
  }

  public async componentDidMount() {
    const gradeList = await StoneGrades.getGrades();
    this.setState({ gradeList });

    this.loadData();
  }

  public render(): JSX.Element {
    const t = this;
    const s = t.state;
    const { request, hasError } = Store;

    if (!request) {
      return (
        <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
          {hasError ? "加载数据错误" : "没有数据"}
        </div>
      );
    }

    let canCancel = false;
    let canEdit = false;
    const isStatusValidForCancelOrEdit =
      request.status === ApprovalStatus.Approving ||
      request.status === ApprovalStatus.Rejected;
    canCancel =
      PermCtl.isAuthorized(Perm.SGCR_CL) && isStatusValidForCancelOrEdit;
    canEdit =
      PermCtl.isAuthorized(Perm.SGCR_CU) && isStatusValidForCancelOrEdit;

    const showPrice = s.canViewSlabRP && s.enableReferencePrice;

    const contents = SGCRHelper.sortSlabsOrContents(request.contents);

    return (
      <div>
        <Group>
          <ESHeader
            label={"大板等级变更单信息"}
            allowEdit={canEdit}
            onEditClick={this.handleEditClick}
          />
          <Group.List lineIndent={15}>
            <Field label="变更单号">{request.orderNumber}</Field>
            <Field label="总片数">{request.contents.length} 片</Field>
            <Field label="状态">{t.getRequestStatus(request.status)}</Field>
            {request.cancelReason ? (
              <Field label="取消原因">{request.cancelReason}</Field>
            ) : null}
            {request.notes ? <Field label="备注">{request.notes}</Field> : null}
          </Group.List>
        </Group>
        {contents && contents.length > 0 ? (
          <Group>
            <ESHeader label={"大板等级变更明细"} />
            <Group.List lineIndent={15} itemIndent={[15, 15]}>
              {contents.map((content) => {
                return (
                  <SlabGradeChangeContentListItem
                    key={content.slabId}
                    item={content}
                    showUnitPrice={showPrice}
                    allowDeletion={false}
                    allowSelection={false}
                  />
                );
              })}
            </Group.List>
          </Group>
        ) : null}
        <Collapsible
          label="提交和审批信息"
          expanded={s.submissionApprovalInfoExpanded}
          onExpansionChanged={t.handleCRExpandedClick}
        >
          <Group.List lineIndent={15}>
            <Field label="提交人">{request.creator}</Field>
            <Field label="提交时间">
              {DateUtil.formatDateTime(request.createdTime)}
            </Field>
            {request.approver ? (
              <Field label="审批人">{request.approver}</Field>
            ) : null}
            {request.approvedTime ? (
              <Field label="审批时间">
                {DateUtil.formatDateTime(request.approvedTime)}
              </Field>
            ) : null}
            {request.approverComments ? (
              <Field label="审批意见">{request.approverComments}</Field>
            ) : null}
          </Group.List>
        </Collapsible>

        <ButtonGroup
          showSubmitButton={canCancel}
          submitButtonLable={"取消等级变更单"}
          submitButtonType={"danger"}
          onSubmit={canCancel ? t.handleCancelClick : () => {}}
          onGoBack={t.goBack}
        />
        <Dialog
          title="取消原因"
          show={this.state.showConfirm}
          type="confirm"
          onConfirm={t.handleCancelConfirmation}
          onCancel={t.handleCloseCancelDialog}
        >
          <textarea
            value={this.state.text}
            onChange={t.handleCancelReasonChange}
          />
        </Dialog>
      </div>
    );
  }

  private handleCancelClick = (): void => {
    this.setState({ showConfirm: true });
  };

  private handleEditClick = () => {
    const path = "/slabgradechangerequests/edit/" + this.state.id;
    this.props.history.push(path);
  };

  private handleCancelReasonChange = (e: any): void => {
    if (e.target.value !== null && e.target.value !== "") {
      this.setState({
        cancelReason: e.target.value,
      });
    }
  };

  private validate(): boolean {
    const t = this;
    const s = t.state;
    let valid = validator.require(s.cancelReason, "取消原因");
    valid =
      valid && validator.textLength(s.cancelReason, null, 300, "取消原因");
    return valid;
  }

  private goBack = (): void => {
    this.props.history.goBack();
  };

  private async loadData(): Promise<void> {
    const t = this;
    const s = t.state;
    await Store.getRequest(s.id);
  }

  private async cancel(): Promise<void> {
    const t = this;
    const s = t.state;

    await Store.cancel(s.id, s.cancelReason);
    if (!Store.hasError) {
      Dialog.alert({
        title: "提示",
        content: "已成功取消等级变更单",
        onConfirm() {},
      });
      t.loadData();
    }
  }

  private handleCancelConfirmation = (): void => {
    this.setState({ showConfirm: false });
    const valid = this.validate();
    if (!valid) {
      return;
    }

    this.cancel();
  };

  private handleCloseCancelDialog = (): void => {
    this.setState({ showConfirm: false });
  };

  private handleCRExpandedClick = (expanded: boolean) => {
    const t = this;
    t.setState({ submissionApprovalInfoExpanded: expanded });
  };

  private getRequestStatus(status: ApprovalStatus): JSX.Element {
    const mrStateName = ApprovalStatusConsts.getState(status).text;
    let className = "";
    switch (status) {
      case ApprovalStatus.NotStarted:
        className = "notStartedWO";
        break;
      case ApprovalStatus.Rejected:
        className = "sawingWO";
        break;
      case ApprovalStatus.Completed:
        className = "trimmingWO";
        break;
      case ApprovalStatus.Cancelled:
        className = "abnormalSO";
        break;
    }
    return <span className={className}>{mrStateName}</span>;
  }
}

export default PageSGCRInfo;
