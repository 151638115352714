import nattyStorage from "natty-storage";
import tenantInfo from "../components/TenantInfo";
import Util from "../components/Util";

type IStorage = ReturnType<typeof nattyStorage>;

abstract class CachedDataStore<T> {
  private tenantStorageKey: string;
  private tenantCacheKey: string;
  private storage: IStorage;

  constructor(storageKey: string, cacheKey: string) {
    this.tenantStorageKey = this.getTenantKey(storageKey);
    this.tenantCacheKey = this.getTenantKey(cacheKey);
    this.storage = null;
  }

  private getTenantKey(suffix: string): string {
    return `${tenantInfo.getAbbreviationCode()}-${suffix}`;
  }

  protected getStorage(): IStorage {
    if (this.storage === null) {
      this.storage = nattyStorage(
        Util.getSessionCacheOption(this.tenantStorageKey, 60),
      );
    }
    return this.storage;
  }

  protected async loadAndCacheData(loadFunc: () => Promise<T>): Promise<void> {
    const cache = this.getStorage();
    const cachedData = cache.has(this.tenantCacheKey);
    if (!cachedData.has) {
      const result = await loadFunc();
      cache.set(this.tenantCacheKey, result);
    }
  }

  protected getCachedData(): T {
    const cache = this.getStorage();
    return cache.get(this.tenantCacheKey);
  }
}

export default CachedDataStore;
