import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../../app/WebAPIClientFactory";
import {
  ISlabForListDTO,
  ISlabGradeChangeContentVM,
  ISlabGradeChangeRequestDTO,
  ISlabGradeChangeRequestVM,
  SlabGradeChangeContentVM,
  SlabGradeChangeRequestVM,
} from "../../../app/WebAPIClients";
import Base from "../../../store/base";
import { ISlabGradeChangeContentForUI } from "../../../components/SlabGradeChangeRequest/interfaces";
import SGCRHelper from "../../../components/SlabGradeChangeRequest/SGCRHelper";
class Store extends Base {
  @observable public notes: string = "";
  @observable public id: number = null;
  @observable public request: ISlabGradeChangeRequestDTO = null;
  @observable public contents: ISlabGradeChangeContentForUI[] = [];
  @observable public slabs: ISlabForListDTO[] = [];

  @action
  public clearData() {
    this.notes = "";
    this.id = null;
    this.request = null;
    this.contents = [];
    this.slabs = [];
  }

  @action
  public updateRequestId = (id: number) => {
    this.id = id;
  };

  @action
  public updateNotes = (notes: string) => {
    this.notes = notes;
  };

  @action
  public updateContents = (slabs: ISlabForListDTO[]) => {
    const sortedSlabs = SGCRHelper.sortSlabsOrContents([...slabs]);

    this.slabs = sortedSlabs;
    const addedSlabs = sortedSlabs.filter(
      (s) => !this.contents.some((c) => c.slabId === s.id),
    );
    const removedContents = this.contents.filter(
      (c) => !sortedSlabs.some((s) => s.id === c.slabId),
    );

    const contents = this.contents.filter(
      (c) => !removedContents.some((r) => r.slabId === c.slabId),
    );
    contents.push(
      ...addedSlabs.map((item) => this.getSlabGradeChangeContentForUI(item)),
    );

    this.contents = SGCRHelper.sortSlabsOrContents(contents);
  };

  @action
  public removeContent = (slabId: number) => {
    const index = this.contents.findIndex((x) => x.slabId === slabId);
    if (index !== -1) {
      this.contents.splice(index, 1);
    }

    // remove the slab from slabs list if it is not in contents
    const slabIndex = this.slabs.findIndex((x) => x.id === slabId);
    if (slabIndex !== -1) {
      this.slabs.splice(slabIndex, 1);
    }
  };

  @action
  public updateToGradeId = (
    content: ISlabGradeChangeContentForUI,
    toGradeId: number | null,
  ) => {
    const index = this.contents.findIndex((x) => x.slabId === content.slabId);
    if (index !== -1) {
      this.contents[index].toGradeId = toGradeId;
    }
  };

  @action
  public create = async () => {
    const client = ClientFactory.getSlabGradeChangeRequestClient();
    const callAPI = async () => {
      const requestData = this.getSGCRVMForSubmission();
      await client.create(SlabGradeChangeRequestVM.fromJS(requestData));
    };

    await this.callAPIFunForSubmit(callAPI);
  };

  @action
  public update = async () => {
    const client = ClientFactory.getSlabGradeChangeRequestClient();
    const callAPI = async () => {
      const requestData = this.getSGCRVMForSubmission();
      await client.update(
        this.id,
        SlabGradeChangeRequestVM.fromJS(requestData),
      );
    };

    await this.callAPIFunForSubmit(callAPI);
  };

  @action
  public loadSGCRFromBackend = async () => {
    const t = this;
    const client = ClientFactory.getSlabGradeChangeRequestClient();
    const slabClient = ClientFactory.getSlabClient();
    const callAPI = async () => {
      const request = await client.get(t.id);
      const slabIds = request.contents.map((item) => item.slabId);
      const slabs = await slabClient.getSlabsByIds(slabIds);
      runInAction(() => {
        this.request = request;
        this.contents = SGCRHelper.sortSlabsOrContents(request.contents);
        this.slabs = SGCRHelper.sortSlabsOrContents(slabs);
      });
    };
    await this.callAPIFunForLoadData(callAPI);
  };

  private getSlabGradeChangeContentForUI = (slab: ISlabForListDTO) => {
    const contentForUI: ISlabGradeChangeContentForUI = {
      slabId: slab.id,
      bundleNo: slab.bundleNumber,
      sequenceNumber: slab.sequenceNumber,
      bundlePrefix: slab.bundlePrefix,
      fromGradeId: slab.gradeId,
      originalUnitPrice: slab.unitPrice,
      toGradeId: null,
      destinationUnitPrice: null,
    };

    return contentForUI;
  };

  private getSGCRVMForSubmission = () => {
    const contentData = this.contents.map((item) => {
      const data: ISlabGradeChangeContentVM = {
        slabId: item.slabId,
        toGradeId: item.toGradeId,
      };
      return SlabGradeChangeContentVM.fromJS(data);
    });
    const requestData: ISlabGradeChangeRequestVM = {
      notes: this.notes,
      contents: contentData,
    };
    return requestData;
  };
}

export default new Store();
