import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Group, TextField, Field, Boxs } from "saltui";
import Perm from "../../../app/Perm";
import {
  ISlabForListDTO,
  IStoneCategoryDTO,
  IStoneGradeDTO,
} from "../../../app/WebAPIClients";
import ButtonGroup from "../../../components/ButtonGroup";
import Collapsible from "../../../components/Collapsible";
import Consts from "../../../components/Consts";
import ESHeader from "../../../components/ESHeader";
import ESPickerField from "../../../components/ESPickerField";
import FilterButtonGroup from "../../../components/FilterButtonGroup";
import PermCtrl from "../../../components/PermCtrl";
import Settings from "../../../components/SettingInfo";
import SettingsDefinition from "../../../components/SettingsDefinition";
import Util from "../../../components/Util";
import SGCRStore from "../edit/store";
import Store, { ISlabFilterData } from "./store";
import PagedList from "../../../components/PagedList";
import SlabUtil from "../../../components/SlabUtil";
import ListItem from "../../../components/ListItem";

const { StoneCategories, StoneGrades, ManufacturingMethod } = Consts;

const { HBox, Box } = Boxs;

declare interface IPageSGCRItemSelectorProps extends RouteComponentProps<{}> {}

declare interface IPageSGCRItemSelectorState {
  filterExpanded: boolean;
  categoryList?: IStoneCategoryDTO[];
  gradeList?: IStoneGradeDTO[];
  gradeOptions?: IOption[];
  enableReferencePrice: boolean;
  canViewSlabRP: boolean;
  selectedSlabs: ISlabForListDTO[];
}
@observer
class PageSGCRItemSelector extends React.Component<
  IPageSGCRItemSelectorProps,
  IPageSGCRItemSelectorState
> {
  constructor(props) {
    super(props);
    this.state = {
      filterExpanded: false,
      enableReferencePrice: Settings.getBoolValue(
        SettingsDefinition.EnableReferencePriceKey,
      ),
      canViewSlabRP: PermCtrl.isAuthorized(Perm.S_RP_R),
      selectedSlabs: [],
    };
  }

  public async componentDidMount() {
    const categoryList = await StoneCategories.getCategories();
    const gradeList = await StoneGrades.getGrades();
    const gradeOptions = Util.getOptionsWithPhoneticForUI(gradeList);

    const selectedSlabs = [...SGCRStore.slabs];
    this.setState({
      categoryList,
      gradeList,
      gradeOptions,
      selectedSlabs,
    });

    await this.reloadData();
  }

  private getInputValue(value: number | null | undefined) {
    return Util.isDefinedAndNotNull(value) ? value.toString() : "";
  }

  public render(): JSX.Element {
    const t = this;
    const s = t.state;
    const {
      slabList,
      slabFilterData,
      hasError,
      loadMoreData,
      pagingInfo,
      totalArea,
      totalSlabCount,
    } = Store;
    const { gradeOptions } = s;

    const showPrice = s.canViewSlabRP && s.enableReferencePrice;

    return (
      <div>
        <Collapsible
          expanded={s.filterExpanded}
          onExpansionChanged={t.handleFilterExpansionChange}
          label="筛选条件"
        >
          <Group.List lineIndent={15} itemIndent={[15, 15]}>
            <TextField
              label="荒料编号"
              value={slabFilterData.bundlePrefix}
              onChange={t.handleBundlePrefixChange}
              placeholder={"请输入荒料编号"}
            />
            <Field label="大板序号">
              <HBox>
                <Box>
                  <input
                    className="scopeValueTextBox"
                    type="number"
                    placeholder="最小值"
                    onChange={t.handleMinSequenceNumberChange}
                    value={t.getInputValue(slabFilterData.minSequenceNumber)}
                  />
                </Box>
                <Box className="textScopeWidth">~</Box>
                <Box>
                  <input
                    className="scopeValueTextBox"
                    type="number"
                    placeholder="最大值"
                    onChange={t.handleMaxSequenceNumberChange}
                    value={t.getInputValue(slabFilterData.maxSequenceNumber)}
                  />
                </Box>
              </HBox>
            </Field>
            <ESPickerField
              label="等级"
              options={gradeOptions}
              onSelect={t.handleSlabGradeChange}
              value={slabFilterData.gradeOptions}
              placeholder={"请选择等级"}
              multiple={true}
            />
            <FilterButtonGroup
              onSearch={t.handleSearch}
              onClearData={t.handleClearData}
            />
          </Group.List>
        </Collapsible>
        <PagedList
          className={
            s.filterExpanded ? "filterForThreeExpanded" : "filterCollapsed"
          }
          pagingInfo={pagingInfo}
          showTotalRecordCount={!s.filterExpanded}
          loadMoreDataActionFun={loadMoreData}
          error={hasError}
          dataType="大板等级变更单"
          showDataLoadingMessage={Util.isNotNullAndNotEmptyArray(slabList)}
        >
          <div>
            <Group>
              <ESHeader label={"大板列表"} />
              <Group.List lineIndent={15} itemIndent={[15, 15]}>
                {slabList && slabList.length && slabList.length > 0 ? (
                  slabList.map((item) => {
                    const listItem = SlabUtil.getSlabInfoForList(
                      item,
                      s.categoryList,
                      s.gradeList,
                    );
                    const description =
                      SlabUtil.getSlabInfoSpecWithShowStockingAreaAndUnitPrice(
                        item,
                        s.categoryList,
                        s.gradeList,
                        showPrice,
                      );
                    const selected = s.selectedSlabs.some(
                      (slab) => slab.id === item.id,
                    );
                    return (
                      <ListItem
                        key={item.id}
                        item={item}
                        avatarTitle={item.sequenceNumber.toString()}
                        title={listItem.title}
                        description={description}
                        onClick={t.handleSlabClick}
                        allowSelection={true}
                        selected={selected}
                        onSelect={t.handleSlabClick}
                      />
                    );
                  })
                ) : (
                  <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
                    {hasError ? "加载数据错误" : "没有数据"}
                  </div>
                )}
              </Group.List>
            </Group>
          </div>
        </PagedList>
        {!s.filterExpanded ? (
          <div className="footerInfo" style={{ bottom: "50px" }}>
            <div>
              <span className="unitPriceText">{totalSlabCount} 片</span>
            </div>
            <div>
              <span className="unitPriceText">{totalArea} m²</span>
            </div>
          </div>
        ) : null}
        <ButtonGroup
          className={"fixedActionBtnContainer"}
          onSubmit={t.handleActionButtonClick}
          onGoBack={t.handleReturn}
          submitButtonLable={"提交"}
        />
      </div>
    );
  }

  private async reloadData(): Promise<void> {
    await Store.reloadData();
  }

  private handleActionButtonClick = async (): Promise<void> => {
    const t = this;
    const s = t.state;

    const selectedSlabs = [...s.selectedSlabs];

    SGCRStore.updateContents(selectedSlabs);

    t.handleReturn();
  };

  private handleSlabClick = (item: ISlabForListDTO): void => {
    const t = this;
    const s = t.state;
    const selectedSlabs = [...s.selectedSlabs];
    const index = selectedSlabs.findIndex((slab) => slab.id === item.id);
    if (index >= 0) {
      selectedSlabs.splice(index, 1);
    } else {
      selectedSlabs.push(item);
    }
    t.setState({ selectedSlabs });
  };

  private handleFilterExpansionChange = (expanded): void => {
    const t = this;
    t.setState({ filterExpanded: expanded });
  };

  private handleSlabGradeChange = (value: IOption[]) => {
    const filterData: ISlabFilterData = {
      ...Store.slabFilterData,
      gradeOptions: value,
    };
    Store.updateSlabFilterData(filterData);
  };

  private handleBundlePrefixChange = (value: string) => {
    const filterData: ISlabFilterData = {
      ...Store.slabFilterData,
      bundlePrefix: value,
    };
    Store.updateSlabFilterData(filterData);
  };

  private handleMinSequenceNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!Util.isDefinedAndNotNull(event)) {
      return;
    }

    const value = event.target.value;
    const seqNo = Util.isDefinedAndNotNull(value) ? parseInt(value) : null;
    const filterData: ISlabFilterData = {
      ...Store.slabFilterData,
      minSequenceNumber: seqNo,
    };
    Store.updateSlabFilterData(filterData);
  };

  private handleMaxSequenceNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!Util.isDefinedAndNotNull(event)) {
      return;
    }

    const value = event.target.value;
    const seqNo = Util.isDefinedAndNotNull(value) ? parseInt(value) : null;
    const filterData: ISlabFilterData = {
      ...Store.slabFilterData,
      maxSequenceNumber: seqNo,
    };
    Store.updateSlabFilterData(filterData);
  };

  private handleSearch = async (): Promise<void> => {
    await this.reloadData();
  };

  private handleClearData = async (): Promise<void> => {
    const p: ISlabFilterData = {
      gradeOptions: [],
      bundlePrefix: undefined,
      minSequenceNumber: undefined,
      maxSequenceNumber: undefined,
    };
    Store.updateSlabFilterData(p);
    await this.reloadData();
  };

  private handleReturn = (): void => {
    this.props.history.goBack();
  };
}

export default PageSGCRItemSelector;
