import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Field, Group, TextField } from "saltui";
import Perm from "../../../app/Perm";
import { IStoneGradeDTO } from "../../../app/WebAPIClients";
import ButtonGroup from "../../../components/ButtonGroup";
import Consts from "../../../components/Consts";
import DateUtil from "../../../components/DateUtil";
import ESDialog from "../../../components/ESDialog";
import ESHeader from "../../../components/ESHeader";
import PermCtrl from "../../../components/PermCtrl";
import Settings from "../../../components/SettingInfo";
import SettingsDefinition from "../../../components/SettingsDefinition";
import Util from "../../../components/Util";
import validator from "../../../components/Validator";
import Store from "./store";
import SlabGradeChangeContentListItem from "../../../components/SlabGradeChangeRequest/SlabGradeChangeContentListItem";
import SGCRHelper from "../../../components/SlabGradeChangeRequest/SGCRHelper";
const { StoneGrades } = Consts;

declare interface IPageSGCRApprovalProps
  extends RouteComponentProps<{ id: string }> {}

declare interface IPageSGCRApprovalState {
  id: number;
  gradeList?: IStoneGradeDTO[];
  comments?: string;
  enableReferencePrice: boolean;
  canViewSlabRP: boolean;
}
@observer
class PageSGCRApproval extends React.Component<
  IPageSGCRApprovalProps,
  IPageSGCRApprovalState
> {
  constructor(props) {
    super(props);
    this.state = {
      id: parseInt(this.props.match.params.id),
      enableReferencePrice: Settings.getBoolValue(
        SettingsDefinition.EnableReferencePriceKey,
      ),
      canViewSlabRP: PermCtrl.isAuthorized(Perm.S_RP_R),
    };
  }

  public async componentDidMount() {
    const gradeList = await StoneGrades.getGrades();
    this.setState({ gradeList });

    this.loadData();
  }

  public render(): JSX.Element {
    const t = this;
    const s = t.state;
    const { request, hasError } = Store;

    if (!request || request.status !== 10) {
      return (
        <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
          {hasError
            ? "加载数据错误"
            : request
            ? "等级变更单已经完成审批"
            : "没有数据"}
        </div>
      );
    }

    const canApprove = PermCtrl.isAuthorized(Perm.SGCR_AP);

    const showPrice = s.canViewSlabRP && s.enableReferencePrice;
    let { orderNumber, contents, creator, createdTime, notes } = request;

    contents = SGCRHelper.sortSlabsOrContents(contents);

    const slabCount = contents.length;

    return (
      <div>
        <Group>
          <ESHeader label={"大板等级变更单信息"} />
          <Group.List lineIndent={15}>
            <Field label="变更单号">{orderNumber}</Field>
            <Field label="总片数">{slabCount} 片</Field>
            <Field label="提交人">{creator}</Field>
            <Field label="创建时间">
              {DateUtil.formatDateTime(createdTime)}
            </Field>
            {notes ? <Field label="备注">{notes}</Field> : null}
          </Group.List>
        </Group>
        {contents && contents.length > 0 ? (
          <Group>
            <ESHeader label={"大板等级变更明细"} />
            <Group.List lineIndent={15} itemIndent={[15, 15]}>
              {contents.map((c) => {
                return (
                  <SlabGradeChangeContentListItem
                    key={c.slabId}
                    item={c}
                    showUnitPrice={showPrice}
                    allowDeletion={false}
                    allowSelection={false}
                  />
                );
              })}
            </Group.List>
          </Group>
        ) : null}
        {canApprove ? (
          <Group>
            <ESHeader label={"审批信息"} />
            <Group.List lineIndent={15}>
              <TextField
                label="审批意见"
                placeholder="请输入"
                onChange={t.handleCommentsChange}
                value={s.comments}
              />
            </Group.List>
          </Group>
        ) : null}
        <ButtonGroup
          showExtraButton={canApprove}
          onExtraButtonClick={t.approve}
          extraButtonLable="批准"
          extraButtonClassName={"t-button-approve"}
          showSubmitButton={canApprove}
          submitButtonLable="拒绝"
          submitButtonType="danger"
          onSubmit={t.reject}
          showGoBackButton={true}
          onGoBack={t.goBack}
        />
      </div>
    );
  }

  private goBack = (): void => {
    this.props.history.goBack();
  };

  private validate = (approvalResult: boolean): boolean => {
    const t = this;
    const s = t.state;
    let valid = true;

    if (approvalResult === false) {
      valid = validator.require(s.comments, "审批意见");
      valid = valid && validator.textLength(s.comments, null, 300, "审批意见");
    }
    return valid;
  };

  private approve = (): void => {
    this.submitData(true);
  };

  private reject = (): void => {
    this.submitData(false);
  };

  private submitData = (approvalResult: boolean): void => {
    const t = this;
    const s = t.state;

    const valid = this.validate(approvalResult);
    if (!valid) {
      return;
    }

    const title: string =
      approvalResult === true ? "审批通过" : "拒绝等级变更申请";
    const content: string =
      approvalResult === true
        ? "您确认批准此等级变更单吗？确认后将不允许修改审批结果！"
        : "您确认拒绝此等级变更单吗？确认后将不允许修改审批结果！";
    const onConfirmFn = async () => {
      if (approvalResult) {
        await Store.approve({
          id: s.id,
          comments: s.comments,
        });
      } else {
        await Store.reject({
          id: s.id,
          comments: s.comments,
        });
      }
      if (!Store.hasError) {
        Util.historyGoBackForExternalLinks(t.props.history);
      }
    };

    ESDialog.DialogConfirm(title, content, onConfirmFn);
  };

  private async loadData(): Promise<void> {
    const t = this;
    const s = t.state;
    await Store.getRequest(s.id);
  }

  private handleCommentsChange = (value: string): void => {
    this.setState({ comments: value });
  };
}

export default PageSGCRApproval;
