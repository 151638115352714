import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";

import { Boxs, CalendarField, Group, NumberInfo, Tab, Table } from "saltui";
import ESButton from "../../../components/ESButton";
import Store from "./store";

import DateUtil from "../../../components/DateUtil";
import Util from "../../../components/Util";
import "./PageReportSalesPerfByCreationTime.styl";
import { PaymentAmountBySalesRepByCreationTimeDTO } from "../../../app/WebAPIClients";

const { Box, HBox } = Boxs;
const { NumberItem } = NumberInfo;

declare interface IPageReportSalesPerfByCreationTimeProps
  extends RouteComponentProps<{}> {}

declare interface IPageReportSalesPerfByCreationTimeState {
  showCalendarField: boolean;
  dataForSalesPerformance: object;
  columnsForSalesPerformance: object[];
  selectedDate: any;
  startDate: string;
  endDate: string;
}
@observer
class PageReportSalesPerfByCreationTime extends React.Component<
  IPageReportSalesPerfByCreationTimeProps,
  IPageReportSalesPerfByCreationTimeState
> {
  constructor(props) {
    super(props);
    this.state = {
      showCalendarField: false,
      dataForSalesPerformance: {},
      columnsForSalesPerformance: [
        {
          dataKey: "paymentType",
          width: 0.25,
          title: "结算类型",
          align: "center",
          render: (cellData, rowData) => {
            return rowData.isSalesRep ? (
              <b>{rowData.paymentType}</b>
            ) : (
              rowData.paymentType
            );
          },
        },
        {
          dataKey: "paidAmount",
          title: "已结算",
          width: 0.25,
          align: "center",
          render: (cellData, rowData) => {
            // format the amount to 2 decimal places and use comma as the thousand separator
            const amountText = Util.round(rowData.paidAmount, 0).toLocaleString(
              "en-US",
            );
            return rowData.isSalesRep ? <b>{amountText}</b> : amountText;
          },
        },
        {
          dataKey: "paymentPendingAmount",
          title: "未结算",
          width: 0.25,
          align: "center",
          render: (cellData, rowData) => {
            // format the amount to 2 decimal places and use comma as the thousand separator
            const amountText = Util.round(
              rowData.paymentPendingAmount,
              0,
            ).toLocaleString("en-US");
            return rowData.isSalesRep ? <b>{amountText}</b> : amountText;
          },
        },
        {
          dataKey: "totalAmount",
          title: "总金额",
          width: 0.25,
          align: "center",
          render: (cellData, rowData) => {
            // format the amount to 2 decimal places and use comma as the thousand separator
            const amountText = Util.round(
              rowData.totalAmount,
              0,
            ).toLocaleString("en-US");
            return rowData.isSalesRep ? <b>{amountText}</b> : amountText;
          },
        },
      ],
      selectedDate: {
        startDate: DateUtil.getCurrentYearStartDate(),
        endDate: DateUtil.getCurrentYearEndDate(),
      },
      startDate: DateUtil.getCurrentYearStartDate(),
      endDate: DateUtil.getCurrentYearEndDate(),
    };
  }

  public async componentDidMount() {
    await this.loadData();
  }

  public render() {
    const t = this;
    const s = t.state;

    const { activeTabIndex, reportData, hasError } = Store;
    let paidAmount = 0,
      paymentPendingAmount = 0,
      paymentAmountBySalesRep: PaymentAmountBySalesRepByCreationTimeDTO[] = [];

    if (reportData) {
      paidAmount = Util.round(reportData.paidAmount, 2);
      paymentPendingAmount = Util.round(reportData.paymentPendingAmount, 2);
      paymentAmountBySalesRep = reportData.paymentAmountBySalesRep;
    }

    let dataForSalesRep: {
      paymentType: string;
      paidAmount: number;
      paymentPendingAmount: number;
      totalAmount: number;
      isSalesRep: boolean;
    }[] = [];

    // 按销售员以及是否完成结算分组统计结算金额
    const totalAmountBySalesRep = paymentAmountBySalesRep.reduce<
      {
        salesRep: string;
        paidAmount: number;
        paymentPendingAmount: number;
        totalAmount: number;
      }[]
    >((acc, curr) => {
      const existing = acc.find((item) => item.salesRep === curr.salesRep);
      if (existing) {
        existing.paidAmount += curr.paidAmount;
        existing.paymentPendingAmount += curr.paymentPendingAmount;
        existing.totalAmount += curr.totalAmount;
      } else {
        acc.push({
          salesRep: curr.salesRep,
          paidAmount: curr.paidAmount,
          paymentPendingAmount: curr.paymentPendingAmount,
          totalAmount: curr.totalAmount,
        });
      }
      return acc;
    }, []);

    // 用totalAmount对totalAmountBySalesRep进行倒序排序
    totalAmountBySalesRep.sort((a, b) => b.totalAmount - a.totalAmount);

    for (const item of totalAmountBySalesRep) {
      dataForSalesRep.push({
        paymentType: item.salesRep,
        paidAmount: item.paidAmount,
        paymentPendingAmount: item.paymentPendingAmount,
        totalAmount: item.totalAmount,
        isSalesRep: true,
      });

      // 从paymentAmountBySalesRep中选择salesRep为item.salesRep的所有元素，加入dataForSalesRep数组
      const paymentAmountBySalesRepItems = paymentAmountBySalesRep
        .filter((d) => d.salesRep === item.salesRep)
        .map((d) => ({
          paymentType: d.paymentType,
          paidAmount: d.paidAmount,
          paymentPendingAmount: d.paymentPendingAmount,
          totalAmount: d.totalAmount,
          isSalesRep: false,
        }));
      paymentAmountBySalesRepItems.sort(
        (a, b) => b.totalAmount - a.totalAmount,
      );
      dataForSalesRep.push(...paymentAmountBySalesRepItems);
    }

    const propsForCalendarField = {
      placeholder: "请选择日期",
      required: false,
      layout: "h",
      type: "dayWithSlot",
      singleMode: false,
      showHalfDay: false,
      showTopPanel: true,
      showWeek: false,
      showDateType: false,
      value: this.state.selectedDate,
      readOnly: false,
      onOk: this.onOk,
    };

    return (
      <div>
        <Tab onChange={t.handleTabChange} activeKey={activeTabIndex}>
          <Tab.Item title="今日报表" />
          <Tab.Item title="本周报表" />
          <Tab.Item title="本月报表" />
          <Tab.Item title="本季报表" />
          <Tab.Item title="自定义查询" />
        </Tab>
        {reportData ? (
          <div className="poReportColor">
            {activeTabIndex === "4" ? (
              <div>
                <Group.Head>
                  <HBox vAlign="center" className="tableTitle">
                    <Box flex={1}>{"选择日期区间"}</Box>
                  </HBox>
                </Group.Head>
                <HBox vAlign="center" className="tableTitle">
                  <Box flex={1}>
                    <CalendarField {...propsForCalendarField} />
                  </Box>
                </HBox>
              </div>
            ) : null}
            <div>
              <Group.Head>
                <HBox vAlign="center" className="tableTitle">
                  <Box flex={1}>{"结算金额汇总"}</Box>
                </HBox>
              </Group.Head>
              <NumberInfo label="总结算金额">
                <NumberItem
                  groupDigits={3}
                  spliter=","
                  number={paidAmount + paymentPendingAmount}
                  unit="元"
                />
              </NumberInfo>
              <NumberInfo label="已结算金额">
                <NumberItem
                  groupDigits={3}
                  spliter=","
                  number={paidAmount}
                  unit="元"
                />
              </NumberInfo>
              <NumberInfo label="未结算金额">
                <NumberItem
                  groupDigits={3}
                  spliter=","
                  number={paymentPendingAmount}
                  unit="元"
                />
              </NumberInfo>
            </div>
            <div>
              <Group.Head>
                <HBox vAlign="center" className="tableTitle">
                  <Box flex={1}>{"结算汇总（按销售代表）"}</Box>
                </HBox>
              </Group.Head>
              <Group.List lineIndent={15}>
                <Table
                  columns={s.columnsForSalesPerformance}
                  data={{ data: dataForSalesRep }}
                  leftFixed={0}
                />
              </Group.List>
            </div>
          </div>
        ) : (
          <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
            {hasError ? "加载数据错误" : "没有数据"}
          </div>
        )}
        <div className="actionBtnContainer">
          <ESButton onClick={t.goBack}>返回</ESButton>
        </div>
      </div>
    );
  }

  private goBack = () => {
    this.props.history.goBack();
  };

  private async loadData(customerStartDate = null, customerEndDate = null) {
    const t = this;
    const s = t.state;

    let startDate = new Date().toISOString();
    let endDate = new Date().toISOString();

    switch (Store.activeTabIndex) {
      case "1":
        startDate = DateUtil.getCurrentWeekStartDate();
        endDate = DateUtil.getCurrentWeekEndDate();
        break;
      case "2":
        startDate = DateUtil.getCurrentMonthStartDate();
        endDate = DateUtil.getCurrentMonthEndDate();
        break;
      case "3":
        startDate = DateUtil.getCurrentQuarterStartDate();
        endDate = DateUtil.getCurrentQuarterEndDate();
        break;
      case "4":
        startDate =
          customerStartDate === null ? s.startDate : customerStartDate;
        endDate = customerEndDate === null ? s.endDate : customerEndDate;
        break;
    }
    await Store.loadData({
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    });
  }

  private handleTabChange = (tab) => {
    if (!tab) {
      return;
    }
    const t = this;
    const s = t.state;
    const activeTabIndex: string = tab.activeKey;
    if (Store.activeTabIndex === activeTabIndex) {
      return;
    }
    Store.changeActiveTab(activeTabIndex);
    t.loadData();
  };

  private onOk = async (value) => {
    const startDate = Util.isDefinedAndNotNull(value.startDate)
      ? DateUtil.formatDate(new Date(value.startDate))
      : value.startDate;
    const endDate = Util.isDefinedAndNotNull(value.endDate)
      ? DateUtil.formatDate(new Date(value.endDate))
      : value.endDate;
    this.setState({
      selectedDate: value,
      startDate,
      endDate,
    });

    if (
      Util.isDefinedAndNotNull(value.startDate) &&
      Util.isDefinedAndNotNull(value.endDate)
    ) {
      await this.loadData(startDate, endDate);
    }
  };
}

export default PageReportSalesPerfByCreationTime;
