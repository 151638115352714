import React from "react";
import { RouteComponentProps } from "react-router";
import { observer } from "mobx-react";
import { Field, Group, TextareaField } from "saltui";
import ESButton from "../../../components/ESButton";
import ESHeader from "../../../components/ESHeader";
import Settings from "../../../components/SettingInfo";
import SettingsDefinition from "../../../components/SettingsDefinition";
import PermCtrl from "../../../components/PermCtrl";
import Perm from "../../../app/Perm";
import Util from "../../../components/Util";
import validator from "../../../components/Validator";
import SlabGradeChangeContentListItem from "../../../components/SlabGradeChangeRequest/SlabGradeChangeContentListItem";
import Store from "./store";
import { ISlabGradeChangeContentForUI } from "../../../components/SlabGradeChangeRequest/interfaces";

declare interface IPageEditSGCRProps
  extends RouteComponentProps<{ id: string }> {}

declare interface IPageEditSGCRState {
  enableReferencePrice: boolean;
  canViewSlabRP: boolean;
}
@observer
class PageEditSGCR extends React.Component<
  IPageEditSGCRProps,
  IPageEditSGCRState
> {
  constructor(props) {
    super(props);
    this.state = {
      enableReferencePrice: Settings.getBoolValue(
        SettingsDefinition.EnableReferencePriceKey,
      ),
      canViewSlabRP: PermCtrl.isAuthorized(Perm.S_RP_R),
    };
  }

  public async componentDidMount() {
    const t = this;
    const props = t.props;
    const action = props.history.action;
    // history.action有以下值："PUSH"、"POP"
    // 1. 等级变更单列表界面的新建变更单按钮使用"PUSH"跳转到这个界面
    // 2. 变更单选择大板的页面使用goBack（POP）跳转回这个界面
    // 所以如果是PUSH，就认为是重新发起的新建动作
    // 可以清空所有状态
    if (action === "PUSH") {
      // 先清除上一次可能留下的状态
      Store.clearData();

      // 将传过来的id存在状态中
      const { isEditing, id } = this.isEditingRequest();
      if (isEditing) {
        Store.updateRequestId(id);

        // 载入等级变更单数据
        await this.loadData();
      }
    }
  }

  public render(): JSX.Element {
    const t = this;
    const s = t.state;

    const { id, notes, contents, slabs } = Store;
    const slabCount = contents.length;

    const showPrice = s.canViewSlabRP && s.enableReferencePrice;

    return (
      <div>
        <ESHeader label={`${id === null ? "新建" : "更新"}大板等级变更单`} />
        <Group.List lineIndent={15}>
          <Field label="总片数"> {slabCount} 片 </Field>
        </Group.List>
        <ESHeader
          label={"大板等级变更明细"}
          allowCreation={true}
          onCreateClick={t.handleAddContentClick}
        />
        <Group.List borderTopNone={true} lineIndent={15} itemIndent={[15, 15]}>
          {contents.map((item) => {
            return (
              <SlabGradeChangeContentListItem
                key={item.slabId}
                item={item}
                slab={slabs.find((s) => s.id === item.slabId)}
                onGradeChange={Store.updateToGradeId}
                showUnitPrice={showPrice}
                allowEditing={true}
                allowDeletion={true}
                allowSelection={false}
                onDelete={t.handleRemoveContentClick}
              />
            );
          })}
        </Group.List>
        <Group>
          <ESHeader label={"其它信息"} />
          <Group.List borderTopNone={true} lineIndent={15} itemIndent={[0, 15]}>
            <TextareaField
              label="备注"
              minRows={2}
              maxRows={3}
              placeholder="备注"
              value={notes}
              onChange={t.handleNotesChange}
            />
          </Group.List>
        </Group>
        <div className="actionBtnContainer">
          <ESButton onClick={t.submitData}>提交</ESButton>
        </div>
      </div>
    );
  }

  private handleAddContentClick = (): void => {
    const t = this;
    const path = "/slabgradechangerequests/itemselector";
    this.props.history.push({ pathname: path });
  };

  private handleRemoveContentClick = (
    content: ISlabGradeChangeContentForUI,
  ): void => {
    Store.removeContent(content.slabId);
  };

  private validate(): boolean {
    let valid = validator.require(Store.contents, "变更明细", true, "添加");

    const { slabs, contents } = Store;
    contents.forEach((content) => {
      const slab = slabs.find((s) => s.id === content.slabId);
      const slabName = slab
        ? `${slab?.bundlePrefix} #${slab?.sequenceNumber}`
        : "";
      valid =
        valid &&
        validator.require(
          content.toGradeId,
          `${slabName}变更后等级`,
          true,
          "选择",
          true,
        );
    });
    valid = valid && validator.textLength(Store.notes, null, 300, "备注");
    return valid;
  }

  private submitData = async (): Promise<void> => {
    const t = this;

    const valid = this.validate();
    if (!valid) {
      return;
    }

    if (Store.id === null) {
      await Store.create();
    } else {
      await Store.update();
    }
    if (!Store.hasError) {
      t.clearData();
      t.props.history.goBack();
    }
  };

  private handleNotesChange = (value: string): void => {
    Store.updateNotes(value);
  };

  private clearData(): void {
    Store.clearData();
  }

  private loadData = async (): Promise<void> => {
    await Store.loadSGCRFromBackend();
  };

  private isEditingRequest(): { isEditing: boolean; id: number | null } {
    const strId = this.props.match.params.id;
    const isEditing = Util.isDefinedAndNotNull(strId);
    const id = isEditing ? parseInt(strId) : null;

    return { isEditing, id };
  }
}

export default PageEditSGCR;
