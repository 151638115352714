import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../../app/WebAPIClientFactory";
import {
  ISlabGradeChangeRequestApprovalVM,
  ISlabGradeChangeRequestDTO,
  SlabGradeChangeRequestApprovalVM,
} from "../../../app/WebAPIClients";
import Base from "../../../store/base";

class Store extends Base {
  @observable public request: ISlabGradeChangeRequestDTO = null;

  @action
  public getRequest = async (id: number): Promise<void> => {
    const t = this;
    const client = ClientFactory.getSlabGradeChangeRequestClient();
    const callAPI = async () => {
      const result = await client.get(id);
      runInAction(() => {
        this.request = result;
      });
    };

    await this.callAPIFunForLoadData(callAPI);
  };

  @action
  public approve = async ({ id, comments }) => {
    const client = ClientFactory.getSlabGradeChangeRequestClient();

    const callAPI = async () => {
      const data: ISlabGradeChangeRequestApprovalVM = { comments };
      await client.approve(id, SlabGradeChangeRequestApprovalVM.fromJS(data));
    };
    await this.callAPIFunForSubmit(callAPI);
  };

  @action
  public reject = async ({ id, comments }) => {
    const client = ClientFactory.getSlabGradeChangeRequestClient();

    const callAPI = async () => {
      const data: ISlabGradeChangeRequestApprovalVM = { comments };
      await client.reject(id, SlabGradeChangeRequestApprovalVM.fromJS(data));
    };
    await this.callAPIFunForSubmit(callAPI);
  };
}

export default new Store();
