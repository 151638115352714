import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Gallery, Grid } from "saltui";
import Perm from "../../app/Perm";
import dtClientSVC from "../../components/DingtalkClientSVC";
import errHandler from "../../components/ErrorHandler";
import ESIcon from "../../components/ESIcon";
import PermCtl from "../../components/PermCtrl";
import SettingInfo from "../../components/SettingInfo";
import settings from "../../components/Settings";
import SettingsDefinition from "../../components/SettingsDefinition";
import tenantInfo from "../../components/TenantInfo";
import Util from "../../components/Util";
import DemoBannerImg from "../../images/DemoBanner.jpg";
import "./PageHome.styl";
import Store from "./store";

declare interface IMenuInfo {
  text: string;
  icon: string;
  route?: string;
  permission?: string[];
  onClick?: () => void;
}
declare interface IPageHomeProps extends RouteComponentProps<{}> {}

declare interface IPageHomeState {
  activeIndex: number;
  images: Array<{
    src: any;
    name: string;
    href: string;
  }>;
  icons: IMenuInfo[];
  id?: number;
}
@observer
class PageHome extends React.Component<IPageHomeProps, IPageHomeState> {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      images: [
        {
          src:
            settings.environment === "production"
              ? tenantInfo.getBannerUrl()
              : DemoBannerImg,
          name: "石材云ERP",
          href: "",
        },
      ],
      icons: [
        {
          text: "荒料管理",
          icon: "show-block",
          route: "blocks",
          permission: [Perm.B_R],
        },
        {
          text: "订单管理",
          icon: "show-salesOrders",
          route: "salesorders",
          permission: [Perm.SO_R, Perm.SO_RA],
        },
        {
          text: "成品管理",
          icon: "show-product",
          route: "products",
          permission: [Perm.S_R, Perm.T_R, Perm.TPC_R],
        },
        {
          text: "生产管理",
          icon: "show-manufacturing",
          route: "workorders",
          permission: [Perm.WO_R],
        },
        {
          text: "审批",
          icon: "show-approval",
          route: "approverview",
          permission: [
            Perm.SO_AP,
            Perm.SS_AP,
            Perm.TO_S_AP,
            Perm.TO_B_AP,
            Perm.TO_T_AP,
            Perm.SRR_AP,
            Perm.SCOR_AP,
          ],
        },
        {
          text: "出厂管理",
          icon: "show-shipping",
          route: "shippingorders",
          permission: [Perm.SPO_R, Perm.SPO_RA],
        },
        {
          text: "客户管理",
          icon: "icon-customers",
          route: "customers",
          permission: [Perm.CU_R],
        },
        {
          text: "结算管理",
          icon: "icon-currencyforhome",
          route: "paymentorders",
          permission: [Perm.SS_R, Perm.SS_RA],
        },
        {
          text: "报表",
          icon: "show-reports",
          route: "reports",
          permission: [
            Perm.RPT_B_I_R,
            Perm.RPT_B_S_R,
            Perm.RPT_SO_R,
            Perm.RPT_SS_R,
            Perm.RPT_S_I_R,
            Perm.RPT_WO_R,
            Perm.RPT_SP_R,
          ],
        },
        {
          text: "系统设置",
          icon: "show-setup",
          route: "admin",
          permission: [
            Perm.SC_C,
            Perm.SC_U,
            Perm.SG_C,
            Perm.SG_U,
            Perm.P_C,
            Perm.P_U,
            Perm.Q_C,
            Perm.Q_U,
            Perm.SA_C,
            Perm.SA_U,
            Perm.PM_C,
            Perm.PM_U,
            Perm.SBM_C,
            Perm.SBM_U,
            Perm.U_SYNC,
            Perm.U_UDBUPWD,
            Perm.UP_ALL_U,
            Perm.UP_SI_U,
          ],
        },
        {
          text: "扫码",
          icon: "icon-scan",
          onClick: this.getScanQrCodeInfo,
        },
        {
          text: "系统信息",
          icon: "icon-info",
          route: "sysinfo",
        },
      ],
    };
  }

  public render() {
    const t = this;

    const trimmingMaxCuts = SettingInfo.getNumberValue(
      SettingsDefinition.TrimmingMaxCutsKey,
    );

    // hide all icons the current user doesn't have permissions to access
    let icons = t.state.icons.filter((i) => {
      return Util.isNotNullAndNotEmpty(i.permission)
        ? PermCtl.isAnyAuthorized(i.permission)
          ? true
          : PermCtl.isAnyAuthorized([Perm.B_TD_AP]) &&
            i.text === "审批" &&
            Util.isDefinedAndNotNull(trimmingMaxCuts) &&
            trimmingMaxCuts > 0
        : true;
    });

    // 如果没有启用阿里钉钉集成或者阿里钉钉JSAPI不可用（代码没有运行在阿里钉钉App中）
    // 则不显示扫码图标
    const dingtalkIntegrationEnabled = SettingInfo.getBoolValue(
      SettingsDefinition.EnableDingtalkIntegrationKey,
    );
    const isDTJSAPIAvailable = dtClientSVC.isJSAPIAvailable();
    if (!dingtalkIntegrationEnabled || !isDTJSAPIAvailable) {
      icons = icons.filter((i) => i.text !== "扫码");
    }

    return (
      <div className="home-page">
        <Gallery images={this.state.images} showNav={true} height={140} />
        <Grid square={true} col={3}>
          {icons.map((item, index) => {
            return (
              <div
                key={"icon" + index}
                className="t-FBV t-FBAC t-FBJC home-grid-item t-TE"
                onClick={
                  item.onClick
                    ? item.onClick.bind(t)
                    : t.handleRoute.bind(t, item.route)
                }
              >
                <div className="home-grid-icon">
                  <ESIcon
                    name="show-icon-bg"
                    className={"icon-bg icon-color" + index}
                  />
                  <ESIcon name={item.icon} className="icon-is" />
                </div>
                <div className="t-FC6 icon-text">{item.text}</div>
              </div>
            );
          })}
        </Grid>
      </div>
    );
  }

  private handleRoute(route) {
    const t = this;
    t.props.history.push(route);
  }

  private getScanQrCodeInfo() {
    dtClientSVC
      .extractQRCode()
      .then(async (content) => {
        await this.goToInfoPageByQrCodeText(content);
      })
      .catch((err) => {
        errHandler.handleErr("扫描失败", err);
      });
  }

  private async goToInfoPageByQrCodeText(qrCodeText) {
    // 把传入的二维码文本转换为json对象
    let info = null;
    try {
      info = JSON.parse(qrCodeText);
    } catch (e) {
      return errHandler.handleErr("您扫描的二维码不是系统生成的二维码！", null);
    }
    // 验证扫描到的文本是否是系统二维码格式
    if (
      !(
        info.v &&
        info.t &&
        ((info.v === 1 && info.id) || (info.v === 2 && info.uniqueId))
      )
    ) {
      return errHandler.handleErr("您扫描的二维码不是系统生成的二维码！", null);
    }

    // 为实现多租户，引入GUID，因此需升级系统中二维码版本，并兼容V1.0版本
    // V1.0版本：所有二维码存储的都是id
    // V2.0版本：使用uniqueId替换id，版本号v为2.0，其它信息不变
    switch (info.v) {
      case 1:
        this.goToPath(info.t, info.id);
        break;
      case 2:
        // 如果二维码版本是2.0，使用uniqueId查询对应的id
        const uniqueId = encodeURIComponent(info.uniqueId);
        await Store.getIdByUniqueId({ uniqueId, type: info.t });
        if (!Store.hasError && Store.id) {
          this.goToPath(info.t, Store.id);
        }

        break;
      default:
        errHandler.handleErr("您扫描的二维码不是系统生成的二维码！", null);
    }
  }

  private goToPath(type: string, id: number) {
    let path = null;
    switch (type) {
      case "SB":
        path = "/products/bundle/info/" + id;
        this.validPermissionAndGoTo([Perm.S_R], "大板", path);
        break;
      case "SPO":
        path = "/shippingorders/info/" + id;
        this.validPermissionAndGoTo([Perm.SPO_R, Perm.SPO_RA], "装车单", path);
        break;
      case "TPC":
        path = "/products/tilepackingcase/info/" + id;
        this.validPermissionAndGoTo([Perm.TPC_R], "工程板包装箱", path);
        break;
      case "SO":
        path = "/salesorders/info/" + id;
        this.validPermissionAndGoTo([Perm.SO_R, Perm.SO_RA], "销售订单", path);
        break;
      case "PO":
        path = "/paymentorders/info/" + id;
        this.validPermissionAndGoTo([Perm.SS_R, Perm.SS_RA], "结算单", path);
        break;
      case "TO":
        path = "/transferorders/info/" + id;
        this.validPermissionAndGoTo(
          [Perm.TO_B_R, Perm.TO_T_R, Perm.TO_S_R],
          "调拨单",
          path,
        );
        break;
      default:
        errHandler.handleErr("您扫描的二维码不是系统生成的二维码！", null);
    }
  }

  private validPermissionAndGoTo(
    permissions: string[],
    name: string,
    path: string,
  ) {
    if (PermCtl.isAnyAuthorized(permissions)) {
      this.props.history.push(path);
    } else {
      return errHandler.handleErr(
        `您没有查看${name}的权限，如需查看，请联系系统管理员添加权限!`,
        null,
      );
    }
  }
}

export default PageHome;
