import { action, observable, runInAction } from "mobx";

import ClientFactory from "../../../app/WebAPIClientFactory";
import {
    ISlabGradeChangeRequestCancellationVM,
    ISlabGradeChangeRequestDTO,
    SlabGradeChangeRequestCancellationVM,
} from "../../../app/WebAPIClients";
import Base from "../../../store/base";

class Store extends Base {
    @observable public request: ISlabGradeChangeRequestDTO = null;

    @action
    public getRequest = async (id: number) => {
        const client = ClientFactory.getSlabGradeChangeRequestClient();
        const callAPI = async () => {
            const result = await client.get(id);
            runInAction(() => {
                this.request = result;
            });
        };

        await this.callAPIFunForLoadData(callAPI);
    };

    @action
    public cancel = async (id: number, comments: string) => {
        const t = this;

        const scorClient = ClientFactory.getSlabGradeChangeRequestClient();
        const callAPI = async () => {
            const data: ISlabGradeChangeRequestCancellationVM = { comments };
            await scorClient.cancel(
                id,
                SlabGradeChangeRequestCancellationVM.fromJS(data),
            );
        };

        await t.callAPIFunForSubmit(callAPI);
    };
}

export default new Store();
