import { observer } from "mobx-react";
import React from "react";

import { Field, Group } from "saltui";

import Store from "./store";

import { RouteComponentProps } from "react-router";
import version from "../../app/releaseInfo.json";
import ESButton from "../../components/ESButton";

declare interface IPageSysInfoProps extends RouteComponentProps<{}> {}

declare interface IPageSysInfoState {}
@observer
class PageSysInfo extends React.Component<
    IPageSysInfoProps,
    IPageSysInfoState
> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public render(): JSX.Element {
        const t = this;
        const s = this.state;
        const { backendSysInfo } = Store;
        return (
            <Group>
                <Group.Head>客户端</Group.Head>
                <Group.List lineIndent={15}>
                    <Field label="版本号">
                        <span>{version.buildVersion}</span>
                    </Field>
                    <Field label="发布时间">
                        <span>{version.releaseTime}</span>
                    </Field>
                </Group.List>
                <Group.Head>服务端</Group.Head>
                <Group.List lineIndent={15}>
                    <Field label="版本号">
                        <span>
                            {backendSysInfo
                                ? backendSysInfo.buildVersion
                                : "未知"}
                        </span>
                    </Field>
                    <Field label="发布时间">
                        <span>
                            {backendSysInfo
                                ? backendSysInfo.releaseTime
                                : "未知"}
                        </span>
                    </Field>
                </Group.List>
                <Group.Head>网络</Group.Head>
                <Group.List lineIndent={15}>
                    <Field label="协议">
                        <span>
                            {backendSysInfo
                                ? backendSysInfo.protocolName
                                : "未知"}
                        </span>
                    </Field>
                    <Field label="本地IP">
                        <span>
                            {backendSysInfo
                                ? backendSysInfo.clientIPAddress
                                : "未知"}
                        </span>
                    </Field>
                </Group.List>
                <div className="actionBtnContainer">
                    <ESButton onClick={t.goBack}>返回</ESButton>
                </div>
            </Group>
        );
    }

    private goBack = (): void => {
        this.props.history.goBack();
    };

    private loadData = async () => {
        await Store.getSystemInfo();
    };
}

export default PageSysInfo;
