import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../../app/WebAPIClientFactory";
import { ISlabForListDTO, SlabBundleStatus } from "../../../app/WebAPIClients";
import Util from "../../../components/Util";
import Base from "../../../store/base";
export interface ISlabFilterData {
  bundlePrefix?: string;
  minSequenceNumber?: number;
  maxSequenceNumber?: number;
  gradeOptions?: IOption[];
}

class Store extends Base {
  @observable public slabList: ISlabForListDTO[] = [];
  @observable public slabFilterData: ISlabFilterData = {};
  @observable public totalArea: number = 0;
  @observable public totalSlabCount: number = 0;

  @action
  public updateSlabFilterData = (filterData: ISlabFilterData) => {
    this.slabFilterData = filterData;
  };

  @action
  public loadMoreData = async () => {
    const t = this;

    const newPageNo = t.pageNo + 1;

    let callAPI = async () => {
      const res = await this.callAPIForSlab(newPageNo);
      const { result, totalArea } = res;
      const slabList = [...t.slabList, ...result];
      runInAction(() => {
        this.slabList = slabList;
        this.totalArea = totalArea;
        this.totalSlabCount = res.pagingInfo.totalRecordCount;
      });
      this.setPagingInfo(res.pagingInfo);
    };

    await t.callAPIFunForLoadMoreData(callAPI);
  };

  @action
  public reloadData = async () => {
    const callAPI = async () => {
      const res = await this.callAPIForSlab();
      const { result, totalArea } = res;
      runInAction(() => {
        this.slabList = result;
        this.totalArea = totalArea;
        this.totalSlabCount = res.pagingInfo.totalRecordCount;
      });
      this.setPagingInfo(res.pagingInfo);
    };

    await this.callAPIFunForLoadData(callAPI);
  };

  private async callAPIForSlab(pageNo: number = 1) {
    const t = this;
    const { gradeOptions, minSequenceNumber, maxSequenceNumber, bundlePrefix } =
      t.slabFilterData;

    const client = ClientFactory.getSlabClient();
    return client.getAll(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      bundlePrefix,
      undefined,
      undefined,
      undefined,
      Util.getValuesByOptions(gradeOptions),
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      [SlabBundleStatus.InStock],
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      100,
      pageNo,
      false,
      minSequenceNumber,
      maxSequenceNumber,
    );
  }
}

export default new Store();
