import { IStoneGradeDTO } from "../app/WebAPIClients";
import ClientFactory from "../app/WebAPIClientFactory";
import CachedDataStore from "./CachedDataStore";

class StoneGradeStore extends CachedDataStore<IStoneGradeDTO[]> {
  private static instance: StoneGradeStore;
  private constructor() {
    super("gradesStorage", "gradesCache");
  }

  public static getInstance(): StoneGradeStore {
    if (!StoneGradeStore.instance) {
      StoneGradeStore.instance = new StoneGradeStore();
    }
    return StoneGradeStore.instance;
  }

  public async getGrade(id: number): Promise<IStoneGradeDTO | null> {
    const gradeList = await this.getGrades();
    return gradeList.find((c) => c.id === id) ?? null;
  }

  public async getGrades(): Promise<IStoneGradeDTO[]> {
    await this.ensureGrades();
    return this.getCachedData();
  }

  private async ensureGrades() {
    const loadDataFn = async () => {
      const client = ClientFactory.getStoneGradeClient();
      const response = await client.getAll(undefined, 1, 1000, true, true);
      return response.result;
    };

    await this.loadAndCacheData(loadDataFn);
  }
}

export default StoneGradeStore.getInstance();
