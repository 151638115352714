import { IStockingAreaDTO, WarehouseType } from "../app/WebAPIClients";
import ClientFactory from "../app/WebAPIClientFactory";
import CachedDataStore from "./CachedDataStore";
import WarehouseStore from "./WarehouseStore";

class ProductStockingAreaStore extends CachedDataStore<IStockingAreaDTO[]> {
  private static instance: ProductStockingAreaStore;
  private constructor() {
    super("productStockingAreaStorage", "productStockingAreaCache");
  }

  public static getInstance(): ProductStockingAreaStore {
    if (!ProductStockingAreaStore.instance) {
      ProductStockingAreaStore.instance = new ProductStockingAreaStore();
    }
    return ProductStockingAreaStore.instance;
  }

  public async getStockingArea(id: number): Promise<IStockingAreaDTO | null> {
    const gradeList = await this.getStockingAreas();
    return gradeList.find((c) => c.id === id) ?? null;
  }

  public async getStockingAreas(): Promise<IStockingAreaDTO[]> {
    await this.ensurePSAs();
    return this.getCachedData();
  }

  public async getFactoryIdByStockingAreaId(
    stockingAreaId: number,
  ): Promise<number | null> {
    if (stockingAreaId === null) {
      return null;
    }

    const stockingAreas = await this.getStockingAreas();
    const warehouses = await WarehouseStore.getWarehouses();

    const stockingArea = stockingAreas.find((sa) => sa.id === stockingAreaId);
    const warehouse = warehouses.find(
      (w) => w.id === stockingArea?.warehouseId,
    );

    return warehouse?.factoryId ?? null;
  }

  private async ensurePSAs() {
    const loadDataFn = async () => {
      const client = ClientFactory.getStockingAreaClient();
      const response = await client.getAll(
        undefined,
        undefined,
        undefined,
        [WarehouseType.Product],
        1000,
        1,
        undefined,
        undefined,
      );
      return response.result;
    };

    await this.loadAndCacheData(loadDataFn);
  }
}

export default ProductStockingAreaStore.getInstance();
